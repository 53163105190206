import { globalCustomerId } from "../contexts/UserContext";
import { UploadConfig, UploadStatus } from "../types/corporaState";
import { isHttpResponseCodeError } from "../utils/isHttpResponseCodeError";
import { apiV2Client, UploadFileResponse } from "./apiV2Client";

export const uploadFile = async ({
  jwt,
  payload,
  onComplete,
  controller
}: {
  jwt: string;
  payload: { corpusId: string; fileOrBlob: File | Blob; isExtractTablesEnabled: boolean };
  onComplete: (params: { status: UploadStatus; data?: UploadFileResponse; error?: UploadConfig["error"] }) => void;
  controller?: AbortController;
}) => {
  const { corpusId, fileOrBlob, isExtractTablesEnabled } = payload;

  const formData = new FormData();
  formData.append("file", fileOrBlob);
  formData.append(
    "table_extraction_config",
    new Blob(
      [
        JSON.stringify({
          extract_tables: isExtractTablesEnabled
        })
      ],
      {
        type: "application/json"
      }
    )
  );

  const headers = new Headers();
  headers.set("Customer-Id", globalCustomerId());
  headers.set("Authorization", `Bearer ${jwt}`);

  const {
    data,
    error,
    response: { status }
  } = await apiV2Client.POST("/v2/corpora/{corpus_key}/upload_file", {
    fetch: (url, ...args) =>
      fetch(url, {
        ...args,
        signal: controller?.signal,
        // openapi-fetch doesn't support FormData as body, so we need to set it manually
        // more details: https://github.com/openapi-ts/openapi-typescript/issues/1214
        body: formData,
        headers
      }),
    params: {
      path: { corpus_key: corpusId }
    }
  });

  if (error) {
    onComplete({ status: "error", error: { msg: error.messages?.join(", ") ?? "Unexpected error", code: status } });
  } else {
    onComplete({ status: "success", data });
  }
};

export const uploadSampleData = async ({
  jwt,
  payload,
  onComplete
}: {
  jwt: string;
  payload: { corpusId: string };
  onComplete: (params: {
    status: UploadStatus;
    data?: UploadFileResponse;
    error?: { code: number; msg: string };
  }) => void;
}) => {
  const response = await fetch("/files/black_hole.html", {
    method: "GET",
    headers: {
      Accept: "application/octet-stream"
    }
  });

  if (isHttpResponseCodeError(response.status)) {
    throw "Could not retrieve sample data.";
  }

  const data = await response.arrayBuffer();
  const blob = new Blob([data]);

  uploadFile({
    jwt,
    payload: {
      corpusId: payload.corpusId,
      fileOrBlob: blob,
      isExtractTablesEnabled: false
    },
    onComplete
  });
};
