import { loadStripe } from "@stripe/stripe-js";
import { PublicAdminServicePromiseClient } from "./generated_protos/services_grpc_web_pb";
import { AdminServicePromiseClient } from "./generated_protos/services_grpc_web_pb";
import { ChatServicePromiseClient } from "./generated_protos/services_grpc_web_pb";
import {
  REACT_APP_ENV,
  REACT_APP_BILLING_PLAN_ID,
  REACT_APP_API_ENDPOINT,
  REACT_APP_SERVING_URL,
  REACT_APP_REST_SERVING_URL,
  REACT_APP_OAUTH_URL,
  REACT_APP_STRIPE_KEY,
  REACT_APP_AUTH_PROVIDER,
  REACT_APP_ORY_SDK_URL,
  REACT_APP_SNOW_URL,
  REACT_APP_ORY_JWT_TEMPLATE_NAME
} from "./envVars";
import { QueryServicePromiseClient } from "./generated_protos/services_grpc_web_pb";

export const IS_PROD = REACT_APP_ENV === "prod";

// Platform
export const BILLING_PLAN_ID = `${REACT_APP_BILLING_PLAN_ID}`;

const ADMIN_BACKEND_URL = `${REACT_APP_API_ENDPOINT}`;
export const PublicAdminService = new PublicAdminServicePromiseClient(ADMIN_BACKEND_URL);
export const AdminService = new AdminServicePromiseClient(ADMIN_BACKEND_URL);

const SERVING_URL = (() => {
  // If the serving URL has been defined in env vars, use that.
  // Otherwise, default to legacy behavior.
  if (REACT_APP_SERVING_URL) {
    return REACT_APP_SERVING_URL;
  }

  return IS_PROD ? "https://wp.serving.vectara.io" : "https://wp.serving.vectara.dev";
})();
export const ChatService = new ChatServicePromiseClient(SERVING_URL);
export const QueryService = new QueryServicePromiseClient(SERVING_URL);

export const REST_SERVING_URL = (() => {
  // If the REST serving URL has been defined in env vars, use that.
  // Otherwise, default to legacy behavior.
  if (REACT_APP_REST_SERVING_URL) {
    return REACT_APP_REST_SERVING_URL;
  }

  return IS_PROD ? "https://api.vectara.io" : " https://api.vectara.dev";
})();

export const OAUTH_URL = (() => {
  // If the OAuth URL has been defined in env vars, use that.
  // Otherwise, default to legacy behavior.
  if (REACT_APP_OAUTH_URL) {
    return REACT_APP_OAUTH_URL;
  }

  return IS_PROD ? "https://auth.vectara.com/oauth2/token" : "https://auth.vectara.dev/oauth2/token";
})();

export const SNOW_URL = REACT_APP_SNOW_URL;

// Stripe
export const stripePromise = loadStripe(REACT_APP_STRIPE_KEY!);

// Auth
type AuthProvider = "ory" | "cognito";
export const AUTH_PROVIDER = REACT_APP_AUTH_PROVIDER as AuthProvider;
export const ORY_SDK_URL = REACT_APP_ORY_SDK_URL;
export const ORY_POOL_PREFIX = "ory:";

// Block operation of the app if auth has been misconfigured.
// This is mostly to prevent us from messing it when developing locally.
export const IS_AUTH_CONFIGURED =
  AUTH_PROVIDER === "cognito" || (AUTH_PROVIDER === "ory" && ORY_SDK_URL && REACT_APP_ORY_JWT_TEMPLATE_NAME);
