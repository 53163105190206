import { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useUserContext } from "./UserContext";
import { StatusCode } from "../generated_protos/status_pb";
import { ComputeAccountSize, ReadAccountSize } from "../apis/accountInfoApi";
import { useNotificationsContext } from "./NotificationsContext";
import { AdminService } from "../backendConfig";

interface AccountSizeContextType {
  computeAccountSize: () => Promise<number | undefined>;
  readAccountSize: ({ isMounted }: { isMounted: boolean }) => Promise<number | undefined>;
  accountSize: number | undefined;
  accountMetadataSize: number | undefined;
  documentsCount: number | undefined;
  docPartsCount: number | undefined;
  accountSizeStatus: StatusCode | undefined;
  accountSizeError: string;
  isLoadingAccountSize: boolean;
}

const AccountSizeContext = createContext<AccountSizeContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const AccountSizeContextProvider = ({ children }: Props) => {
  const { getJwt, customer, abilities } = useUserContext();
  const { addNotification } = useNotificationsContext();

  const [accountSize, setAccountSize] = useState<number>();
  const [accountMetadataSize, setAccountMetadataSize] = useState<number>();
  const [documentsCount, setDocumentsCount] = useState<number>();
  const [docPartsCount, setDocPartsCount] = useState<number>();
  const [accountSizeStatus, setAccountSizeStatus] = useState<StatusCode | undefined>(StatusCode.OK);
  const [accountSizeError, setAccountSizeError] = useState("");
  const [isLoadingAccountSize, setIsLoadingAccountSize] = useState(false);

  const computeAccountSize = async () => {
    if (!customer) return;
    if (isLoadingAccountSize) return;

    setIsLoadingAccountSize(true);

    try {
      const jwt = await getJwt();
      const response = await ComputeAccountSize(jwt, AdminService, customer.customerId);

      let totalSizeChars = 0;
      let totalSizeMetadata = 0;
      let totalDocumentsCount = 0;
      let totalDocPartsCount = 0;

      for (const i in response.sizeList) {
        const sizeData = response.sizeList[i];
        totalSizeChars += sizeData.numChars;
        totalSizeMetadata += sizeData.numMetadataChars;
        totalDocumentsCount += sizeData.numDocs;
        totalDocPartsCount += sizeData.numParts;
      }

      setAccountSize(totalSizeChars);
      setAccountMetadataSize(totalSizeMetadata);
      setDocumentsCount(totalDocumentsCount);
      setDocPartsCount(totalDocPartsCount);
      setAccountSizeStatus(response.status?.code);

      if (response.status && response.status.code !== StatusCode.OK) {
        addNotification(response.status.statusDetail, "danger", 3000);
        setAccountSizeError(response.status.statusDetail);
      } else {
        setAccountSizeError("");
      }
      setIsLoadingAccountSize(false);

      return totalSizeChars;
    } catch (err) {
      datadogRum.addError(err);
      console.log(err);
      setIsLoadingAccountSize(false);
      addNotification(`Couldn't compute account size`, "danger", 3000);
    }
  };

  const readAccountSize = async ({ isMounted }: { isMounted: boolean }) => {
    if (!customer) return;

    setIsLoadingAccountSize(true);

    try {
      const jwt = await getJwt();
      const response = await ReadAccountSize(jwt, AdminService, Number(customer.customerId));

      if (!isMounted) {
        return;
      }

      setAccountSizeStatus(response.status?.code);
      setIsLoadingAccountSize(false);

      // This happens when the account size for a customer was never computed.
      // Generally, a combination of two reasons:
      // 1. It is a new customer (less than 1 day old)
      // 2. The StatsPublisher (The service that computes the sizes daily and
      //    puts them in StatsDb) hasn’t run yet for this customer.
      if (response.status?.code === StatusCode.NOT_FOUND) {
        setAccountSizeError("");
      } else if (response.status?.code === StatusCode.OK) {
        const size = response.timeddataList[0]?.size;

        setAccountSize(size?.numChars);
        setAccountMetadataSize(size?.numMetadataChars);
        setDocumentsCount(size?.numDocs);
        setDocPartsCount(size?.numParts);
        setAccountSizeError("");

        return (size?.numChars ?? 0) + (size?.numMetadataChars ?? 0);
      } else {
        if (response.status) {
          addNotification(response.status.statusDetail, "danger", 3000);
          setAccountSizeError(response.status.statusDetail);
        }
      }
    } catch (err) {
      datadogRum.addError(err);
      if (!isMounted) {
        return;
      }
      setIsLoadingAccountSize(false);
      console.log(err);
    }
  };

  useEffect(() => {
    const componentState = { isMounted: true };
    if (customer && abilities?.canReadAccountSize) {
      readAccountSize(componentState);
    }
    return () => {
      componentState.isMounted = false;
    };
  }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AccountSizeContext.Provider
      value={{
        computeAccountSize,
        readAccountSize,
        accountSize,
        accountMetadataSize,
        documentsCount,
        docPartsCount,
        accountSizeStatus,
        accountSizeError,
        isLoadingAccountSize
      }}
    >
      {children}
    </AccountSizeContext.Provider>
  );
};

export const useAccountSizeContext = () => {
  const context = useContext(AccountSizeContext);
  if (context === undefined) {
    throw new Error("useAccountSizeContext must be used within a AccountSizeContextProvider");
  }
  return context;
};
