import { CustomerRole } from "../../generated_protos/admin/admin_security_pb";
import { Abilities, PageAccess, UserCredential, UserCredentials } from "../../types/user";

const CREATE_CORPUS_ROLES: Array<keyof UserCredentials> = ["isOwner", "isAdmin", "isCorpusAdmin"];
const READ_ACCOUNT_SIZE_ROLES: Array<keyof UserCredentials> = ["isOwner", "isAdmin", "isCorpusAdmin", "isBillingAdmin"];

// As long as the user has one of the required roles, they can perform the action.
const hasRequiredRole = (requiredRoles: Array<keyof UserCredentials>, roles: UserCredentials) =>
  requiredRoles.findIndex((requiredRole) => roles[requiredRole]) !== -1;

const hasAnyCredentials = (userCredentials?: UserCredentials, credentials?: UserCredential[]) => {
  if (!userCredentials || !credentials) return false;
  return credentials.some((credential) => userCredentials[credential]);
};

export const distillRoles = (
  roleList?: Array<CustomerRole>
): {
  userCredentials: UserCredentials;
  abilities: Abilities;
  pageAccess: PageAccess;
} => {
  const isOwner = roleList?.find((role) => role === CustomerRole.CUSTOMERROLE_OWNER) === undefined ? false : true;

  const isAdmin = roleList?.find((role) => role === CustomerRole.CUSTOMERROLE_ADMIN) === undefined ? false : true;

  const isCorpusAdmin =
    roleList?.find((role) => role === CustomerRole.CUSTOMERROLE_CORPUS_ADMIN) === undefined ? false : true;

  const isBillingAdmin =
    roleList?.find((role) => role === CustomerRole.CUSTOMERROLE_BILLING_ADMIN) === undefined ? false : true;

  const userCredentials = {
    isOwner,
    isAdmin,
    isCorpusAdmin,
    isBillingAdmin
  };

  const canCreateCorpus = hasRequiredRole(CREATE_CORPUS_ROLES, userCredentials);
  const canReadAccountSize = hasRequiredRole(READ_ACCOUNT_SIZE_ROLES, userCredentials);
  const canDeleteAccount = hasRequiredRole(["isOwner"], userCredentials);

  return {
    userCredentials,
    abilities: {
      canCreateCorpus,
      canReadAccountSize,
      canDeleteAccount
    },
    pageAccess: {
      team: hasAnyCredentials(userCredentials, ["isOwner", "isAdmin"]),
      apiKeys: hasAnyCredentials(userCredentials, ["isOwner", "isAdmin", "isCorpusAdmin"]),
      billing: hasAnyCredentials(userCredentials, ["isOwner", "isBillingAdmin"]),
      chatHistory: hasAnyCredentials(userCredentials, ["isOwner"])
    }
  };
};
