import { useLocation, useNavigate } from "react-router-dom";
import { BiMenu, BiTime } from "react-icons/bi";
import {
  VuiAppHeader,
  VuiButtonPrimary,
  VuiFlexContainer,
  VuiFlexItem,
  VuiIcon,
  VuiIconButton
} from "@vectara/vectara-ui";
import { AccountMenu } from "./accountMenu/AccountMenu";
import { useUserContext } from "../../../contexts/UserContext";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import { sectionToName } from "../appNav/AppNav";
import { HeaderBreadcrumbLabel } from "./HeaderBreadcrumbLabel";
import { ApiMenu } from "./apiMenu/ApiMenu";
import { useSurveyContext } from "../../../contexts/SurveyContext";
import { useTrialPlanReminder } from "../useTrialPlanReminder";
import { BILLING_PATH, IS_BILLING_ENABLED } from "../../../constants";
import "./appHeader.scss";

const humanizePathPart = (part: string) => part.charAt(0).toUpperCase() + part.slice(1);

function AppHeader() {
  const { isSurveyActive } = useSurveyContext();
  const { customer, trialPlan } = useUserContext();
  const { setIsNavOpen, isNavPinnedOpen, customHeaderContent } = useLayoutContext();
  const { pathname } = useLocation();

  // TODO: Ensure this branch is never hit.
  if (!customer) {
    return <div>…</div>;
  }

  const navPath = pathname.split("/");
  const baseNavPath = navPath[2];
  const pageName = sectionToName[baseNavPath as keyof typeof sectionToName] ?? "";
  const headerContent = customHeaderContent ?? (
    <HeaderBreadcrumbLabel>{humanizePathPart(pageName)}</HeaderBreadcrumbLabel>
  );

  if (isSurveyActive) return null;

  return (
    <VuiAppHeader
      data-testid="loggedInHeader"
      growRight
      left={
        <VuiFlexContainer alignItems="center" spacing="xs">
          {!isNavPinnedOpen && (
            <VuiFlexItem>
              <VuiIconButton
                aria-label="Show navigation"
                data-testid="openNavButton"
                icon={
                  <VuiIcon>
                    <BiMenu />
                  </VuiIcon>
                }
                onMouseMove={(e) => {
                  // Prevent movement on the button itself from closing the nav
                  // immediately after the user clicks on it.
                  e.stopPropagation();
                }}
                onClick={() => {
                  setIsNavOpen(true);
                }}
              />
            </VuiFlexItem>
          )}

          {headerContent}
        </VuiFlexContainer>
      }
      right={
        <VuiFlexContainer justifyContent="end" alignItems="center" spacing="s" className="appHeaderSearchContainer">
          {IS_BILLING_ENABLED && trialPlan && pageName !== "Billing" && (
            <VuiFlexItem grow={false}>
              <TrialPlanButton isExpired={trialPlan.isExpired} expiration={trialPlan.expiryTimestamp} />
            </VuiFlexItem>
          )}

          <VuiFlexItem grow={false}>
            <ApiMenu />
          </VuiFlexItem>

          <VuiFlexItem grow={false}>
            <AccountMenu />
          </VuiFlexItem>
        </VuiFlexContainer>
      }
    />
  );
}

const TrialPlanButton = ({ isExpired, expiration }: { isExpired: boolean; expiration: number }) => {
  const reminder = useTrialPlanReminder(isExpired, expiration);
  const navigate = useNavigate();

  return (
    <VuiFlexContainer>
      <VuiButtonPrimary
        size="m"
        icon={
          <VuiIcon size="s">
            <BiTime />
          </VuiIcon>
        }
        color={isExpired ? "danger" : "success"}
        onClick={() => navigate(`console/${BILLING_PATH}/upgrade`)}
      >
        {reminder}
      </VuiButtonPrimary>
    </VuiFlexContainer>
  );
};

export default AppHeader;
