import { datadogRum, RumEvent } from "@datadog/browser-rum";
import { IS_DATADOG_ENABLED } from "../constants";
import {
  REACT_APP_ALLOW_SESSION_MONITORING,
  REACT_APP_DATADOG_APP_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_ENV,
  REACT_APP_DATADOG_SERVICE,
  REACT_APP_DATADOG_SITE,
  REACT_APP_DATADOG_VERSION
} from "../envVars";

const IGNORED_ERRORS = [
  "%c[dreamdata-analytics]: Failed to load Clearbit script font-weight: bold;",
  "Clearbit tags.js snippet included twice."
];

export const initDatadog = () => {
  if (!IS_DATADOG_ENABLED) return;

  if (
    !REACT_APP_ALLOW_SESSION_MONITORING ||
    !REACT_APP_DATADOG_APP_ID ||
    !REACT_APP_DATADOG_CLIENT_TOKEN ||
    !REACT_APP_DATADOG_SITE ||
    !REACT_APP_DATADOG_SERVICE ||
    !REACT_APP_DATADOG_ENV ||
    !REACT_APP_DATADOG_VERSION
  ) {
    return;
  }

  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APP_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: REACT_APP_DATADOG_SITE,
    service: REACT_APP_DATADOG_SERVICE,
    env: REACT_APP_DATADOG_ENV,
    version: REACT_APP_DATADOG_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: (event: RumEvent) => {
      if (event.type === "error" && IGNORED_ERRORS.includes(event.error.message)) {
        return false;
      }
      return true;
    }
  });
};
