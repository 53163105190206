import { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  VuiCard,
  VuiFlexContainer,
  VuiFlexItem,
  VuiLink,
  VuiSpacer,
  VuiTitle,
  VuiButtonPrimary,
  VuiFormGroup,
  VuiTextInput,
  VuiCallout
} from "@vectara/vectara-ui";
import { resetForgottenPassword } from "../../../utils/auth/passwordAuth";
import { GetCustomerLoginAttribs } from "../../../apis/publicApi";
import { useNotificationsContext } from "../../../contexts/NotificationsContext";
import { PublicAdminService } from "../../../backendConfig";
import { isNullOrWhitespace } from "../../../utils/isNullOrWhitespace";
import { extractCustomerId, extractUserName } from "../../../utils/queryParams";
import { Field } from "../types";
import logo from "../../../images/logo-vectara-dark.png";
import { PasswordField } from "../PasswordField";
import { validatePassword } from "../../../utils/regex";
import { datadogRum } from "@datadog/browser-rum";

type Form = {
  customerId: Field;
  username: Field;
  verificationCode: Field;
  password: Field;
  confirmedPassword: Field;
};

type Fields = keyof Form;

export const SetNewPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addNotification } = useNotificationsContext();

  const [form, setForm] = useState<Form>({
    customerId: {
      value: "",
      isValid: true
    },
    username: {
      value: "",
      isValid: true
    },
    verificationCode: {
      value: "",
      isValid: true
    },
    password: {
      value: "",
      isValid: true
    },
    confirmedPassword: {
      value: "",
      isValid: true
    }
  });

  const [isSettingPassword, setIsSettingPassword] = useState(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    const { customerId, userName } = location?.state || {};
    const suggestedCustomerId = extractCustomerId(searchParams);
    const suggestedUsername = extractUserName(searchParams);

    setForm({
      ...form,
      customerId: {
        value: customerId || suggestedCustomerId || "",
        isValid: true
      },
      username: {
        value: userName || suggestedUsername || "",
        isValid: true
      }
    });
  }, [location?.state, searchParams]);

  const setField = (name: Fields, value: string) => {
    setForm({ ...form, [name]: { value: value, isValid: value.length > 0 } });
  };

  const validateForm = () => {
    const { customerId, username, verificationCode, password, confirmedPassword } = form;

    const isCustomerIdValid =
      customerId.value.length > 0 && customerId.value.length <= 11 && !isNaN(Number(customerId.value));
    const isUsernameValid = !isNullOrWhitespace(username.value);
    const isPasswordValid = validatePassword(password.value);
    const isConfirmedPasswordValid = validatePassword(confirmedPassword.value);
    const isPasswordMatch = password.value === confirmedPassword.value;
    const isVerificationCodeValid = !isNullOrWhitespace(verificationCode.value);

    setForm({
      customerId: { ...customerId, isValid: isCustomerIdValid },
      username: { ...username, isValid: isUsernameValid },
      password: { ...password, isValid: isPasswordValid },
      confirmedPassword: {
        ...confirmedPassword,
        isValid: isConfirmedPasswordValid && isPasswordMatch,
        error: !isPasswordMatch ? "Passwords must match." : undefined
      },
      verificationCode: { ...verificationCode, isValid: isVerificationCodeValid }
    });

    return (
      isCustomerIdValid &&
      isUsernameValid &&
      isPasswordValid &&
      isConfirmedPasswordValid &&
      isPasswordMatch &&
      isVerificationCodeValid
    );
  };

  /**
   * Submit the password reset request. On success, this will redirect to the login page.
   */
  const submitNewPassword = async () => {
    if (!validateForm()) return;

    try {
      setIsSettingPassword(true);
      const { customerId, verificationCode, username, password } = form;
      const { region, userPoolId, userPoolWebClientId } = await GetCustomerLoginAttribs(
        PublicAdminService,
        customerId.value
      );
      await resetForgottenPassword(username.value, verificationCode.value, password.value, {
        awsRegion: region,
        userPoolId,
        userPoolWebClientId
      });

      setIsSettingPassword(false);
      addNotification(`Password reset was successful.`, "success");
      navigate("/login", { state: { customerId, userName: username.value } });
    } catch (e: any) {
      datadogRum.addError(e);
      setIsSettingPassword(false);

      let error = e.message;
      if (e.code === "CodeMismatchException") {
        error = e.message;
      } else if (e.code === "InvalidPasswordException" || e.code === "InvalidParameterException") {
        error = `Password is invalid: ${e.message}`;
      }

      console.log(e);
      addNotification(error, "danger");
      setError(error);
    }
  };

  return (
    <div>
      <VuiCard
        padding="m"
        className="loggedOutPageForm"
        header={
          <VuiFlexContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing="s"
            className="loggedOutFormHeader"
          >
            <VuiFlexItem grow={false} shrink={false}>
              <VuiLink href="/">
                <img src={logo} alt="Vectara logo" className="loggedOutFormLogo" />
              </VuiLink>
            </VuiFlexItem>

            <VuiFlexItem grow={1} shrink={1}>
              <VuiTitle size="s" align="center" data-testid="setNewPasswordTitle">
                <h1>Set new password</h1>
              </VuiTitle>
            </VuiFlexItem>
          </VuiFlexContainer>
        }
        body={
          <>
            <VuiCallout
              title="Enter your verification code and a new password."
              color="primary"
              headingElement="h2"
              size="s"
            />

            <VuiSpacer size="l" />

            {error && (
              <>
                <VuiCallout color="danger" headingElement="h2" title="Couldn't save new password">
                  {error}
                </VuiCallout>
                <VuiSpacer size="l" />
              </>
            )}

            {/* Form and submit button might be required by user-agent for autocompletion of fields.
            https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */}
            <form onSubmit={(e) => e.preventDefault()}>
              <button type="submit" style={{ display: "none" }} />

              <VuiFormGroup
                label="Customer ID"
                labelFor="customerId"
                helpText="Your customer number is a numeric code of up to ten digits. You can find it in your welcome email."
                errors={!form.customerId.isValid ? ["Enter valid customer ID."] : undefined}
              >
                <VuiTextInput
                  fullWidth
                  id="customerId"
                  name="customerId"
                  autoComplete="customerId"
                  value={form.customerId.value}
                  onChange={(e) => setField("customerId", e.target.value.trim())}
                  onSubmit={submitNewPassword}
                  data-testid="customerIdInput"
                />
              </VuiFormGroup>

              <VuiSpacer size="l" />

              <VuiFormGroup
                label="Email or username"
                labelFor="username"
                helpText="If you're an account owner, enter your email. If you're a team member, enter the username assigned to you in your welcome email."
                errors={!form.username.isValid ? ["Enter valid email or username."] : undefined}
              >
                <VuiTextInput
                  fullWidth
                  id="username"
                  name="username"
                  autoComplete="username"
                  value={form.username.value}
                  onChange={(e) => setField("username", e.target.value.trim())}
                  onSubmit={submitNewPassword}
                  data-testid="usernameInput"
                />
              </VuiFormGroup>

              <VuiSpacer size="l" />

              <VuiFormGroup
                label="Verification code"
                labelFor="verificationCode"
                helpText="Check your registered email address for the verification code."
                errors={!form.verificationCode.isValid ? ["Enter valid verification code."] : undefined}
              >
                <VuiTextInput
                  fullWidth
                  id="verificationCode"
                  name="verificationCode"
                  value={form.verificationCode.value}
                  onChange={(e) => setField("verificationCode", e.target.value)}
                  onSubmit={submitNewPassword}
                  data-testid="verificationCodeInput"
                />
              </VuiFormGroup>

              <VuiSpacer size="l" />

              <PasswordField
                id="newPassword"
                name="newPassword"
                label="New password"
                data-testid="newPasswordInput"
                helpText="Your password must contain at least one digit, and be eight characters or longer."
                onSubmit={submitNewPassword}
                password={form.password}
                onChange={(value) => setField("password", value)}
                fullWidth
              />

              <VuiSpacer size="l" />

              <PasswordField
                id="confirmedPassword"
                name="confirmedPassword"
                label="Confirm password"
                data-testid="confirmPasswordInput"
                onSubmit={submitNewPassword}
                password={form.confirmedPassword}
                onChange={(value) => setField("confirmedPassword", value)}
                fullWidth
              />

              <VuiSpacer size="l" />

              <VuiButtonPrimary
                color="primary"
                isDisabled={isSettingPassword}
                fullWidth
                onClick={submitNewPassword}
                isLoading={isSettingPassword}
              >
                {isSettingPassword ? "Saving new password…" : "Save new password"}
              </VuiButtonPrimary>
            </form>
          </>
        }
      />
    </div>
  );
};
