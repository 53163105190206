import { Job } from "../../apis/apiV2Client";

export type CorpusJobs = Record<Exclude<Job["type"], undefined>, Job[]>;

export const buildJobsMap = (jobs: Job[]) => {
  return jobs.reduce((acc, job) => {
    job.corpus_keys?.forEach((corpusKey) => {
      acc[corpusKey] = acc[corpusKey] || {};
      if (job.type) {
        acc[corpusKey][job.type] = acc[corpusKey][job.type] || [];
        acc[corpusKey][job.type].push(job);
      }
    });
    return acc;
  }, {} as Record<string, CorpusJobs>);
};
