import { lazy, Suspense, useEffect } from "react";
import classNames from "classnames";
import { Routes, Route, Navigate, Outlet, Link, useLocation } from "react-router-dom";
import { AnalyticsProvider } from "use-analytics";
import { FocusStyleManager } from "@blueprintjs/core";
import { Elements } from "@stripe/react-stripe-js";
import {
  LinkProps,
  VuiAppContent,
  VuiAppLayout,
  VuiContextProvider,
  VuiFlexContainer,
  VuiSpacer,
  VuiSpinner,
  VuiTitle
} from "@vectara/vectara-ui";
import AppHeader from "./views/common/appHeader/AppHeader";
import { AnalyticsContextProvider } from "./contexts/AnalyticsContext";
import { UserContextProvider, useUserContext } from "./contexts/UserContext";
import { UploadContextProvider } from "./contexts/UploadContext";
import { JobsContextProvider } from "./contexts/JobsContext";
import { CorpusGlobalContextProvider } from "./contexts/CorpusGlobalContext";
import { ConfigContextProvider, useConfigContext } from "./contexts/ConfigContext";
import { PlanContextProvider } from "./contexts/PlanContext";
import { NotificationsContextProvider } from "./contexts/NotificationsContext";
import { LayoutContextProvider, useLayoutContext } from "./contexts/LayoutContext";
import { AUTH_PROVIDER, IS_AUTH_CONFIGURED, stripePromise } from "./backendConfig";
import { PaymentContextProvider } from "./contexts/PaymentContext";
import { UsageContextProvider } from "./contexts/UsageContext";
import { analytics } from "./contexts/utils/analytics";
import { useInitializeApp } from "./useInitializeApp";
import { AccountSizeContextProvider } from "./contexts/AccountSizeContext";
import { GetStartedContextProvider } from "./contexts/GetStartedContext";
import { ViewLoading } from "./views/common/ViewLoading";
import { RequestPasswordResetPage } from "./views/userAuth/changePassword/RequestPasswordResetPage";
import { SetNewPasswordPage } from "./views/userAuth/changePassword/SetNewPasswordPage";
import { AnnouncementsContextProvider } from "./contexts/AnnouncementsContext";
import SubmitOryRecoveryCode from "./views/userAuth/changePassword/SubmitOryRecoveryCode";
import SetNewOryPassword from "./views/userAuth/changePassword/SetNewOryPassword";
import { IntlProvider, ThemeProvider } from "@ory/elements";
import { AppNav } from "./views/common/appNav/AppNav";
import { CheckEmailToResetPasswordPage } from "./views/userAuth/changePassword/CheckEmailToResetPasswordPage";
import { SurveyContextProvider, useSurveyContext } from "./contexts/SurveyContext";
import { CORPORA_PATH, IS_ANALYTICS_ENABLED, SURVEY_PATH } from "./constants";
import { OnboardingSurvey } from "./views/survey/OnboardingSurvey";
import { initDatadog } from "./utils/datadog";
import "@blueprintjs/core/lib/css/blueprint.css";
import "./app.scss";

// Only show a focus outline around elements when tabbing through the UI.
FocusStyleManager.onlyShowFocusOnTabs();

// Logged out UX.
const SignupPage = lazy(() => import("./views/userAuth/signupPage/SignupPage"));
const SignupAwsPage = lazy(() => import("./views/userAuth/signupPage/SignupAwsPage"));
const LoginPage = lazy(() => import("./views/userAuth/loginPage/LoginPage"));
const VerifyEmailPage = lazy(() => import("./views/userAuth/signupPage/VerifyEmailPage"));
const AcceptInvitationPage = lazy(() => import("./views/userAuth/changePassword/AcceptInvitationPage"));

// Logged in UX.
const Console = lazy(() => import("./views/Console"));

const LoggedOutAppLayout = () => (
  <VuiAppLayout full>
    <VuiAppContent padding="xl" fullWidth className="loggedOutContainer">
      <Outlet />
    </VuiAppContent>
  </VuiAppLayout>
);

const LoggedInAppLayout = () => {
  const { isSurveyActive } = useSurveyContext();
  const { isNavOpen, isNavPinnedOpen, setIsNavOpen } = useLayoutContext();

  const classes = classNames("loggedInAppLayout", {
    "loggedInAppLayout-hasNav": isNavPinnedOpen,
    "loggedInAppLayout-isWizardActive": isSurveyActive
  });

  return (
    <>
      <AppNav />
      <div
        className={classes}
        onMouseMove={() => {
          if (!isNavOpen || isNavPinnedOpen) return;
          setIsNavOpen(false);
        }}
      >
        <AppHeader />
        <Outlet />
      </div>
    </>
  );
};

const AppRoutes = () => {
  const { hasInitiallyAuthenticated, isAuthenticating, isAuthenticated } = useUserContext();
  const { isLoadingConfig } = useConfigContext();
  const { isSurveyActive } = useSurveyContext();

  // This is the main entry point into the app.
  useInitializeApp();

  if (!hasInitiallyAuthenticated || isAuthenticating || isLoadingConfig) {
    return (
      <VuiFlexContainer className="authLoader" direction="column" justifyContent="center" alignItems="center">
        <VuiSpinner size="l" />
        <VuiSpacer size="l" />
        <VuiTitle size="xs">
          <h1>Loading Vectara</h1>
        </VuiTitle>
      </VuiFlexContainer>
    );
  }

  if (!isAuthenticated) {
    return (
      <ThemeProvider themeOverrides={{}}>
        <IntlProvider>
          <Routes>
            {/* Ory Routes */}
            {/* Moved Ory Routes out of LoggedOutAppLayout for styling purpose */}
            {AUTH_PROVIDER === "ory" && <Route path="/submitRecoveryCode" element={<SubmitOryRecoveryCode />} />}
            {AUTH_PROVIDER === "ory" && <Route path="/setNewOryPassword" element={<SetNewOryPassword />} />}

            <Route element={<LoggedOutAppLayout />}>
              <Route path="/requestResetPassword" element={<RequestPasswordResetPage />} />
              <Route path="/setNewPassword" element={<SetNewPasswordPage />} />
              <Route path="/checkEmailToResetPassword" element={<CheckEmailToResetPasswordPage />} />
              {/* Maintain backwards compatibility with https://bitbucket.org/zir-ai/platform/src/a0962c37cb527f0641568130803794cda084eb35/admin/sycamore/src/main/java/com/vectara/admin/actions/RegisterCustomer.java?at=master#lines-322 */}
              <Route path="/forgotPassword" element={<SetNewPasswordPage />} />
              <Route
                path="/acceptInvitation"
                element={
                  <Suspense fallback={<ViewLoading />}>
                    <AcceptInvitationPage />
                  </Suspense>
                }
              />
              <Route
                path="/login"
                element={
                  <Suspense fallback={<ViewLoading />}>
                    <LoginPage />
                  </Suspense>
                }
              />
              {/* Split between AWS and regular signup to create two distinct flows. */}
              <Route
                path="/signup"
                element={
                  <Suspense fallback={<ViewLoading />}>
                    <SignupPage />
                  </Suspense>
                }
              />
              <Route
                path="/signupAws"
                element={
                  <Suspense fallback={<ViewLoading />}>
                    <SignupAwsPage />
                  </Suspense>
                }
              />
              <Route
                path="/validate-email"
                element={
                  <Suspense fallback={<ViewLoading />}>
                    <VerifyEmailPage />
                  </Suspense>
                }
              />

              <Route path="*" element={<Navigate replace to="/login" />} />
            </Route>
          </Routes>
        </IntlProvider>
      </ThemeProvider>
    );
  }

  // This is where we want to redirect folks to after they login,
  // regardless of auth mechanism. Place this after the initialization
  // guards above, to avoid any potential race condition between showing
  // the overview page and the walkthrough.
  const LOGIN_LANDING_PAGE = isSurveyActive ? `/console/${SURVEY_PATH}` : `/console/${CORPORA_PATH}`;

  return (
    <PlanContextProvider>
      <PaymentContextProvider>
        <UsageContextProvider>
          <AccountSizeContextProvider>
            <JobsContextProvider>
              <CorpusGlobalContextProvider>
                <UploadContextProvider>
                  <GetStartedContextProvider>
                    <AnnouncementsContextProvider>
                      <Routes>
                        <Route element={<LoggedInAppLayout />}>
                          <Route
                            path={`/console/${SURVEY_PATH}`}
                            element={
                              <Suspense
                                fallback={
                                  <VuiAppLayout>
                                    <ViewLoading />
                                  </VuiAppLayout>
                                }
                              >
                                <OnboardingSurvey />
                              </Suspense>
                            }
                          />

                          <Route path="/console/" element={<Navigate replace to={LOGIN_LANDING_PAGE} />} />

                          <Route
                            path="/console/*"
                            element={
                              isSurveyActive ? (
                                <Navigate replace to={LOGIN_LANDING_PAGE} />
                              ) : (
                                <Suspense fallback={<ViewLoading />}>
                                  <Console />
                                </Suspense>
                              )
                            }
                          />

                          <Route
                            path="*"
                            // Include the query string in the redirect so we preserve the awsToken if the user
                            // comes here from AWS Marketplace while already logged in.
                            element={<Navigate replace to={`${LOGIN_LANDING_PAGE}${window.location.search}`} />}
                          />
                        </Route>
                      </Routes>
                    </AnnouncementsContextProvider>
                  </GetStartedContextProvider>
                </UploadContextProvider>
              </CorpusGlobalContextProvider>
            </JobsContextProvider>
          </AccountSizeContextProvider>
        </UsageContextProvider>
      </PaymentContextProvider>
    </PlanContextProvider>
  );
};

export const App = () => {
  const location = useLocation();
  useEffect(() => {
    initDatadog();
  }, []);

  const linkProvider = (linkConfig: LinkProps) => {
    const { className, href, onClick, children, ...rest } = linkConfig;

    return (
      <Link className={className} to={href ?? ""} onClick={onClick} {...rest}>
        {children}
      </Link>
    );
  };

  const pathProvider = () => {
    return location.pathname;
  };

  if (!IS_AUTH_CONFIGURED) {
    return (
      <VuiAppLayout full>
        <VuiAppContent padding="xl" fullWidth>
          <VuiTitle size="xl">
            <h1>Auth has been misconfigured</h1>
          </VuiTitle>
        </VuiAppContent>
      </VuiAppLayout>
    );
  }

  const app = (
    <Elements stripe={stripePromise}>
      <VuiContextProvider linkProvider={linkProvider} pathProvider={pathProvider}>
        <AnalyticsContextProvider>
          <UserContextProvider>
            <NotificationsContextProvider>
              <LayoutContextProvider>
                <ConfigContextProvider>
                  <SurveyContextProvider>
                    {/* This prevents this error when switching back and forth between */}
                    {/* the signup and login pages: https://github.com/facebook/react/issues/25629 */}
                    <Suspense
                      fallback={
                        <VuiAppLayout>
                          <ViewLoading />
                        </VuiAppLayout>
                      }
                    >
                      <AppRoutes />
                    </Suspense>
                  </SurveyContextProvider>
                </ConfigContextProvider>
              </LayoutContextProvider>
            </NotificationsContextProvider>
          </UserContextProvider>
        </AnalyticsContextProvider>
      </VuiContextProvider>
    </Elements>
  );

  if (IS_ANALYTICS_ENABLED && analytics) {
    return <AnalyticsProvider instance={analytics}>{app}</AnalyticsProvider>;
  }

  return app;
};
