import { createContext, useContext, ReactNode, useState } from "react";
import { sendFetchJobsRequest } from "../apis/jobsApi";
import { useUserContext } from "./UserContext";
import { updateJobs } from "./jobs/updateJobs";
import { CorpusJobs, buildJobsMap } from "./jobs/buildJobsMap";
import { sortJobsByPriority } from "./jobs/sortJobsByPriority";
import { getActiveReplaceFilterJobs } from "./jobs/getActiveReplaceFilterJobs";
import { Job, JobStateEnum } from "../apis/apiV2Client";
import { datadogRum } from "@datadog/browser-rum";

interface JobsContextType {
  isLoadingJobs: boolean;
  jobs: Job[];
  loadJobs: (corpusKeys: string[] | string) => Promise<void>;
  getJobsForCorpus: (corpusKey: string) => Job[] | undefined;
  getJobForCorpus: (corpusKey: string) => Job | undefined;
  getCanEditFilterAttributes: (corpusKey: string) => boolean;
  activeReplaceFilterJobs: Job[];
}

const JobsContext = createContext<JobsContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const JobsContextProvider = ({ children }: Props) => {
  const { getJwt, customer } = useUserContext();
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);
  const [jobsMap, setJobsMap] = useState<Record<string, CorpusJobs>>({});
  const [jobs, setJobs] = useState<Job[]>([]);

  // TODO: Define array of corpus keys so Corpora.tsx can fetch
  // a single page's worth of jobs.
  const loadJobs = async (corpusKeys: string[] | string) => {
    if (!customer) return;
    try {
      setIsLoadingJobs(true);
      const jwt = await getJwt();
      const keys = Array.isArray(corpusKeys) ? corpusKeys : [corpusKeys];
      const data = await sendFetchJobsRequest(jwt, customer.customerId, keys);

      setIsLoadingJobs(false);
      const updatedJobs = updateJobs(jobs, data.jobs ?? [], keys);
      setJobs(updatedJobs);

      // Naively rebuilding the map every time we load the job is inefficient but
      // it's simple. We should optimize this if it turns out to be a bottleneck.
      setJobsMap(buildJobsMap(updatedJobs));
    } catch (err) {
      datadogRum.addError(err);
      setIsLoadingJobs(false);
      console.log(err);
    }
  };

  const getJobsForCorpus = (corpusKey: string) => {
    const corpusJobs = jobsMap[corpusKey];
    // Order them by priority so the first one is most important to the user.
    return corpusJobs ? sortJobsByPriority(corpusJobs) : undefined;
  };

  const getJobForCorpus = (corpusKey: string) => {
    // Get the most important job for the corpus.
    const jobs = getJobsForCorpus(corpusKey);
    return jobs ? jobs[0] : undefined;
  };

  const getCanEditFilterAttributes = (corpusKey: string) => {
    const RETRYABLE_JOB_STATES = [JobStateEnum.aborted, JobStateEnum.failed, JobStateEnum.failed_will_retry] as const;

    const job = getJobForCorpus(corpusKey);

    if (!job) return true;

    return Boolean(
      (job?.type && job.type !== "replace_filter_attributes") ||
        (job?.state && RETRYABLE_JOB_STATES.some((state) => state === job.state))
    );
  };

  const activeReplaceFilterJobs = getActiveReplaceFilterJobs(jobs);

  return (
    <JobsContext.Provider
      value={{
        isLoadingJobs,
        jobs,
        loadJobs,
        getJobsForCorpus,
        getJobForCorpus,
        getCanEditFilterAttributes,
        activeReplaceFilterJobs
      }}
    >
      {children}
    </JobsContext.Provider>
  );
};

export const useJobsContext = () => {
  const context = useContext(JobsContext);
  if (context === undefined) {
    throw new Error("useJobsContext must be used within a JobsContextProvider");
  }
  return context;
};
