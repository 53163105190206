import { Auth } from "@aws-amplify/auth";
import { IS_PROD } from "../../backendConfig";
import { orySdk } from "./orySdk";
import { getCustomerId, getSession, setSession } from "../localStorage/customer";
import { StatusCode } from "../../generated_protos/status_pb";
import { AdminServicePromiseClient } from "../../generated_protos/services_grpc_web_pb";
import { ExtendSession } from "../../apis/usersApi";
import { jwtDecode } from "jwt-decode";
import { datadogRum } from "@datadog/browser-rum";
import { REACT_APP_ORY_JWT_TEMPLATE_NAME } from "../../envVars";

/**
 * JWTs are used to authenticate API calls. This function retrieves
 * the cached JWT or refreshes it if it has expired. Don't call this
 * function directly. Use the getJwt method returned by the
 * useUserContext hook instead.
 */
export const refreshJwt = async (isOryAuth: boolean, AdminService: AdminServicePromiseClient) => {
  let jwt;
  if (isOryAuth) {
    jwt = await getOryJwt(AdminService);
  } else {
    const session = await Auth.currentSession();
    jwt = session.getIdToken().getJwtToken();
  }

  // Expose JWT for tests.
  if (!IS_PROD) {
    // @ts-expect-error Doesn't exist on window.
    window.jwt = jwt;
  }

  return jwt;
};

export const refreshOryJwt = async () => {
  try {
    const tokenTemplateName = REACT_APP_ORY_JWT_TEMPLATE_NAME;

    const response = await orySdk.toSession({
      tokenizeAs: tokenTemplateName
    });

    const result = response.data;
    const jwtExpiry = jwtDecode(result?.tokenized ?? "")?.exp;

    setSession({
      expiresAt: result?.expires_at,
      jwt: result?.tokenized,
      sessionId: result?.id,
      email: result.identity?.traits?.email,
      username: result.identity?.traits?.user_info?.username,
      customerId: result.identity?.traits?.user_info?.customer_id,
      jwtExpiry: jwtExpiry ? new Date(jwtExpiry * 1000) : null
    });

    return {
      id: result?.id,
      expiresAt: result?.expires_at,
      tokenized: result?.tokenized,
      customerId: result.identity?.traits?.user_info?.customer_id,
      username: result.identity?.traits?.user_info?.username,
      email: result.identity?.traits?.email,
      userId: result.identity?.id
    };
  } catch (e: any) {
    datadogRum.addError(e);
    if (e.response.status !== 200) {
      setSession(null);
      return null;
    }
    console.log(e);
  }
};

const getOryJwt = async (AdminService: AdminServicePromiseClient) => {
  const session = getSession();
  const currentJwtExpirationTime = new Date(session?.jwtExpiry ?? "").getTime();
  const now = new Date().getTime();
  const timeUntilCurrentJwtExpiresMs = session?.jwtExpiry ? currentJwtExpirationTime - now : -1;
  const TWO_MINUTES_MS = 120000;

  if (timeUntilCurrentJwtExpiresMs >= TWO_MINUTES_MS) {
    // If current session's JWT will continue to be valid for more than 2 minutes, use it.
    return session?.jwt;
  } else {
    // If current session's JWT will expire in less than 2 minutes, refresh it and extend the session with the new token.
    const newJwt = await refreshOryJwt();
    const extendedSession = await ExtendSession(
      newJwt?.tokenized ?? "",
      AdminService,
      getCustomerId() ?? "",
      session?.sessionId ?? ""
    );

    if (extendedSession.status?.code !== StatusCode.OK) {
      throw new Error(`failed to extend session: ${extendedSession}`);
    }

    return newJwt?.tokenized;
  }
};
