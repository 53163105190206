import { createContext, useContext, ReactNode, useState } from "react";
import { useConfigContext } from "./ConfigContext";
import { SurveyAnswers, SurveyQuestionId, QuestionSchema } from "../views/survey/types";
import { useUserContext } from "./UserContext";
import { submitSurvey } from "../views/survey/submitSurvey";
import { surveySchema } from "../views/survey/surveySchema";
import { datadogRum } from "@datadog/browser-rum";
import { IS_ANALYTICS_ENABLED } from "../constants";

interface SurveyContextType {
  isSurveyActive: boolean;
  isSurveyComplete: boolean;
  isSurveySubmitted: boolean;
  currentQuestion: QuestionSchema;
  currentQuestionIndex: number;
  totalQuestions: number;
  hasPreviousQuestion: boolean;
  hasNextQuestion: boolean;
  goToPreviousQuestion: () => void;
  goToNextQuestion: () => void;
  completeSurvey: (data: SurveyAnswers) => void;
  skipSurvey: () => void;
}

const SurveyContext = createContext<SurveyContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const SurveyContextProvider = ({ children }: Props) => {
  const { getJwt, customer, analyticsAuthToken, anonymousId } = useUserContext();
  const { getIsSurveyComplete, setIsSurveyComplete } = useConfigContext();

  const [currentQuestion, setCurrentQuestion] = useState<QuestionSchema>(surveySchema[0]);
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
  const isSurveyComplete = Boolean(getIsSurveyComplete());

  // The survey depends on Snow, so it's only active if analytics are enabled.
  const isSurveyActive = IS_ANALYTICS_ENABLED && !isSurveyComplete;

  const completeSurvey = async (answers: SurveyAnswers) => {
    setIsSurveySubmitted(true);

    const answersPayload = (Object.keys(answers) as Array<SurveyQuestionId>).reduce(
      (acc, SurveyQuestionId) => {
        const answer = answers[SurveyQuestionId];
        const question = surveySchema.find((question) => question.id === SurveyQuestionId);

        const answerPayload = {
          question_id: SurveyQuestionId,
          question_text: question?.question || "",
          question_type: "SINGLE_CHOICE",
          answers: [
            {
              answer_id: answer.selection,
              answer_text: answer.customAnswer ? answer.customAnswer.trim() : undefined
            }
          ]
        };

        acc.push(answerPayload);
        return acc;
      },
      [] as Array<{
        question_id: SurveyQuestionId;
        question_text: string;
        answers: Array<{
          answer_id: string;
          answer_text?: string;
        }>;
      }>
    );

    const payload = {
      anonymous_id: anonymousId,
      poll_id: "2024_april_initial_poll",
      customer_id: customer?.customerId ?? "",
      analytics_auth_token: analyticsAuthToken ?? "",
      source: "CONSOLE",
      questions: answersPayload
    };

    // We deliberately end the survey without waiting for a response
    // because the user is doing us a favor by filling out the survey
    // and the last thing they care about is whether it was successfully
    // submitted.
    try {
      const jwt = await getJwt();
      submitSurvey({ jwt, customerId: customer?.customerId ?? "", payload });
    } catch (e) {
      datadogRum.addError(e);
      console.log(e);
    }

    setIsSurveyComplete(true);
  };

  const skipSurvey = () => {
    setIsSurveyComplete(true);
  };

  const currentQuestionIndex = surveySchema.findIndex((question) => question.id === currentQuestion.id);
  const hasPreviousQuestion = currentQuestionIndex > 0;
  const hasNextQuestion = currentQuestionIndex < surveySchema.length - 1;

  const goToPreviousQuestion = () => {
    if (hasPreviousQuestion) {
      setCurrentQuestion(surveySchema[currentQuestionIndex - 1]);
    }
  };

  const goToNextQuestion = () => {
    if (hasNextQuestion) {
      setCurrentQuestion(surveySchema[currentQuestionIndex + 1]);
    }
  };

  return (
    <SurveyContext.Provider
      value={{
        isSurveyActive,
        currentQuestion,
        currentQuestionIndex,
        totalQuestions: surveySchema.length,
        hasPreviousQuestion,
        hasNextQuestion,
        goToPreviousQuestion,
        goToNextQuestion,
        isSurveyComplete,
        isSurveySubmitted,
        completeSurvey,
        skipSurvey
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

export const useSurveyContext = () => {
  const context = useContext(SurveyContext);
  if (context === undefined) {
    throw new Error("useSurveyContext must be used within a SurveyContextProvider");
  }
  return context;
};
