import { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { PaymentMethod } from "@stripe/stripe-js";
import { StatusCode } from "../generated_protos/status_pb";
import { UpdatePaymentDetails } from "../apis/billingApi";
import { HasDefaultPaymentSource } from "../apis/billingApi";
import { usePlanContext } from "./PlanContext";
import { useUserContext } from "./UserContext";
import { AdminService } from "../backendConfig";
import { useNotificationsContext } from "./NotificationsContext";
import { PlanName } from "../generated_protos/admin/admin_signup_pb";
import { datadogRum } from "@datadog/browser-rum";

type PaymentModalMode = "upsellToGrowth" | "edit";

interface PaymentContextType {
  isPaymentModalOpen: boolean;
  paymentModalMode: PaymentModalMode | undefined;
  setIsPaymentModalOpen: (mode: PaymentModalMode | false) => void;
  hasPaymentSource?: boolean;
  updatePaymentSource: (paymentSource: PaymentMethod) => void;
  isUpdatingPaymentSource: boolean;
  isLoadingPaymentSource: boolean;
}

const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const PaymentContextProvider = ({ children }: Props) => {
  const { getJwt, customer, pageAccess } = useUserContext();

  const { switchPlan } = usePlanContext();
  const { addNotification } = useNotificationsContext();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentModalMode, setPaymentModalMode] = useState<PaymentModalMode>();
  const [isLoadingPaymentSource, setIsLoadingPaymentSource] = useState(false);
  const [isUpdatingPaymentSource, setIsUpdatingPaymentSource] = useState(false);
  const [hasPaymentSource, setHasPaymentSource] = useState<boolean>();

  useEffect(() => {
    // If billing access is restricted, the usage won't be able to review payment source.
    if (pageAccess?.billing) {
      checkPaymentSource();
    }
  }, [customer, pageAccess?.billing]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkPaymentSource = async () => {
    if (!customer) return;

    const jwt = await getJwt();
    setIsLoadingPaymentSource(true);

    HasDefaultPaymentSource(jwt, AdminService, customer.customerId)
      .then((result) => {
        setIsLoadingPaymentSource(false);
        setHasPaymentSource(result?.status?.code === StatusCode.OK);
      })
      .catch((err) => {
        datadogRum.addError(err);
        console.log(err);
        setIsLoadingPaymentSource(false);
        setHasPaymentSource(undefined);
      });
  };

  const updatePaymentSource = async (paymentSource: PaymentMethod) => {
    if (!customer) return;

    const jwt = await getJwt();
    setIsUpdatingPaymentSource(true);

    UpdatePaymentDetails(jwt, AdminService, customer.customerId, paymentSource.id)
      .then((status) => {
        setIsUpdatingPaymentSource(false);
        setIsPaymentModalOpen(false);
        if (status.status?.code === StatusCode.OK) {
          addNotification("Payment method updated successfully", "success");
          switchPlan(PlanName.PLAN_NAME__STANDARD, "Standard");
          setHasPaymentSource(true);
        } else {
          if (status.status) addNotification(status.status.statusDetail, "danger");
        }
      })
      .catch((err) => {
        datadogRum.addError(err);
        setIsUpdatingPaymentSource(false);
        setIsPaymentModalOpen(false);
        addNotification(`Error while updating payment method ${err}`, "danger");
        console.log(err);
      });
  };

  return (
    <PaymentContext.Provider
      value={{
        isPaymentModalOpen,
        paymentModalMode,
        setIsPaymentModalOpen: (mode: PaymentModalMode | false) => {
          if (mode === "upsellToGrowth") {
            setIsPaymentModalOpen(true);
            setPaymentModalMode("upsellToGrowth");
            return;
          }

          if (mode === "edit") {
            setIsPaymentModalOpen(true);
            setPaymentModalMode("edit");
            return;
          }

          setIsPaymentModalOpen(false);
          setPaymentModalMode(undefined);
        },
        isLoadingPaymentSource,
        hasPaymentSource,
        updatePaymentSource,
        isUpdatingPaymentSource
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => {
  const context = useContext(PaymentContext);
  if (context === undefined) {
    throw new Error("usePaymentContext must be used within a PaymentContextProvider");
  }
  return context;
};
