import {
  DeleteCorpusRequest,
  ResetCorpusRequest,
  UpdateCorpusEnablementRequest,
  ComputeCorpusSizeRequest,
  ComputeCorpusSizeResponse,
  DeleteCorpusResponse
} from "../generated_protos/admin/admin_corpus_pb";
import { AdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";
import { REST_SERVING_URL } from "../backendConfig";
import { apiV2Client, CustomDimension, FilterAttribute } from "./apiV2Client";

export const sendFetchCorporaRequest = async (
  jwt: string,
  customerId: string,
  filterText?: string,
  apiPageKey?: string,
  limit?: number
) => {
  const { data, error } = await apiV2Client.GET("/v2/corpora", {
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    params: {
      query: {
        limit,
        filter: filterText,
        page_key: apiPageKey
      }
    }
  });

  if (error) {
    throw error;
  }

  return data;
};

export const sendFetchCorpusRequest = async (jwt: string, corpusKey: string, customerId: string) => {
  const { data, error, response } = await apiV2Client.GET("/v2/corpora/{corpus_key}", {
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    params: {
      path: {
        corpus_key: corpusKey
      }
    }
  });

  if (error) {
    throw { ...error, status: response.status };
  }

  return data;
};

export const sendUpdateCorpusRequest = async (
  jwt: string,
  customerId: string,
  corpusKey: string,
  corpusData: Record<string, any>
) => {
  const { data, error } = await apiV2Client.PATCH("/v2/corpora/{corpus_key}", {
    params: { path: { corpus_key: corpusKey } },
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    body: corpusData
  });

  if (error) {
    throw error;
  }

  return data;
};

export const DeleteCorpus = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  corpusId: number,
  customerId: string
): Promise<DeleteCorpusResponse.AsObject | undefined> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new DeleteCorpusRequest();
    request.setCorpusId(corpusId);
    request.setCustomerId(Number(customerId));

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .deleteCorpus(request, args)
      .then((resp) => {
        if (resp.getStatus()?.getCode() === 0) {
          resolve(undefined);
        } else {
          reject(new Error(resp.getStatus()?.getStatusDetail()));
        }
      })
      .catch((reason) => reject(reason));
  });
};

export const ResetCorpus = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  corpusId: number,
  customerId: string
) => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ResetCorpusRequest();
    request.setCorpusId(corpusId);
    request.setCustomerId(Number(customerId));

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    // setTimeout(resolve, 5000);
    adminService
      .resetCorpus(request, args)
      .then((resp) => {
        if (resp.getStatus()?.getCode() === 0) {
          resolve(undefined);
        } else {
          reject(new Error(resp.getStatus()?.getStatusDetail()));
        }
      })
      .catch((reason) => reject(reason));
  });
};

export const UpdateCorpusEnablement = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  corpusId: number,
  customerId: string,
  isEnabled = false
) => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new UpdateCorpusEnablementRequest();
    request.setCorpusId(corpusId);
    request.setEnable(isEnabled);

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .updateCorpusEnablement(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: UpdateCorpusEnablement");
          return;
        }
        resolve(respObj);
      })
      .catch((reason) => reject(reason));
  });
};

export const ComputeCorpusSize = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  corpusId: number,
  customerId: string
): Promise<ComputeCorpusSizeResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ComputeCorpusSizeRequest();
    request.setCorpusId(corpusId);

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .computeCorpusSize(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: ReadCorpus");
          return;
        }
        resolve(respObj);
      })
      .catch((reason) => reject(reason));
  });
};

export type CreateCorpusRequestConfig = {
  corpusKey: string;
  name: string;
  description: string;
  documentsAreQuestions: boolean;
  filterAttrs: FilterAttribute[];
  customDims: CustomDimension[];
  encoderId: number;
};

export const generateCreateCorpusRequest = (
  jwt: string,
  customerId: string,
  { corpusKey, name, description, documentsAreQuestions, filterAttrs, customDims, encoderId }: CreateCorpusRequestConfig
) => {
  const body = {
    key: corpusKey,
    name,
    description,
    documents_are_questions: documentsAreQuestions,
    encoder_id: `enc_${encoderId}`, // Required for v2 compatibility
    filter_attributes: filterAttrs,
    custom_dimensions: customDims
  };

  const endpoint = "/v2/corpora";

  return {
    method: "POST",
    url: `${REST_SERVING_URL}${endpoint}`,
    endpoint,
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    body
  } as const;
};

export type CreateCorpusRequest = ReturnType<typeof generateCreateCorpusRequest>;

export const sendCreateCorpusRequest = async (jwt: string, customerId: string, config: CreateCorpusRequestConfig) => {
  const { method, endpoint, headers, body } = generateCreateCorpusRequest(jwt, customerId, config);
  return await apiV2Client[method](endpoint, { headers, body });
};
